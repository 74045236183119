import logo from './assets/logo.png';
import './App.css';

function HomePage() {
  return (
    <div className="App">
      <canvas class="noise"></canvas>
      <img src={logo} className="App-logo" alt="logo" />
    </div>
  );
}


// const canvas = document.querySelector('canvas'),
//                 ctx = canvas.getContext('2d');

// canvas.width = canvas.height = 128

// function resize() {
// 	canvas.width = window.innerWidth * window.devicePixelRatio
// 	canvas.height = window.innerHeight * window.devicePixelRatio
// 	canvas.style.width = window.innerWidth + 'px'
// 	canvas.style.height = window.innerHeight + 'px'
// }

// window.onresize = resize;

// function noise(ctx) {
    
// 	const w = ctx.canvas.width,
// 				h = ctx.canvas.height,
// 				iData = ctx.createImageData(w, h),
// 				buffer32 = new Uint32Array(iData.data.buffer),
// 				len = buffer32.length
// 	  let i = 0

// 	for(; i < len;i++)
		
// 		if (Math.random() < 0.5) buffer32[i] = 0xffffffff;

// 		ctx.putImageData(iData, 0, 0);
// }

// (function loop() {
//     noise(ctx);
//     requestAnimationFrame(loop);
// })();


// var canvas = document.createElement("canvas");
// var context = canvas.getContext("2d");
// canvas.width = window.innerWidth
// canvas.height = window.innerHeight
// document.body.appendChild(canvas); 

// context.fillRect(0, 0, canvas.width, canvas.height);

// window.addEventListener('resize', () => {
//   canvas.width = window.innerWidth
//   canvas.height = window.innerHeight
//   // draw(canvas)
// })

// ;

// setInterval(function() {
//   let posX =  Math.random() * window.innerWidth;
//   let posY =  Math.random() * window.innerHeight;

//   // Draw a circle particle on the canvas
//   context.beginPath();
//   context.fillStyle = "white";
//   // After setting the fill style, draw an arc on the canvas
//   context.rect(posX, posY, 10, 10); 
//   context.closePath();
//   context.fill();
// }, 30);


export default HomePage;
