import logo from "./assets/logo.png";
import "./ArtistsPage.css";

function ArtistsPage() {
  return (
    <div>
      <div className="card">
        {/* <img src={logo} className="card" alt="logo" /> */}
        DATA
      </div>
      <div className="card">
        {/* <img src={logo} className="card" alt="logo" /> */}
        Triboro
      </div>
    </div>
  );
}
export default ArtistsPage;
