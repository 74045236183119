import React, { useImperativeHandle, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";

import "./index.css";
import reportWebVitals from "./reportWebVitals";

import "@fortawesome/fontawesome-free/js/all.js";

import HomePage from "./HomePage";
import ArtistsPage from './ArtistsPage';
import ContactPage from './ContactPage';


let Header = () => {
  const [open, setOpen] = useState(true);
  return open ? (
    <div>
      <nav>
        <div className="nav-close" onClick={() => setOpen(false)}>
          <i class="far fa-times-circle fa-lg" ></i>
        </div>
        <ul>
          <li>
            <NavLink exact activeClassName="active" to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/artists">
              Artists
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/about">
              Shop
            </NavLink>
          </li>
               <li>
            <NavLink activeClassName="active" to="/contact">
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  ) : (
    <div className="nav-open" onClick={() => setOpen(true)}>
      <i class="fas fa-bars fa-lg"></i>
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <div>
        <Header></Header>
        <Switch>
          <Route path="/about"></Route>
          <Route path="/artists">
            <ArtistsPage></ArtistsPage>
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
        </Switch>
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
