import logo from "./assets/logo.png";
import "./ContactPage.css";

function ContactPage() {
  return (
    <div>
      <div className="card">
        {/* <img src={logo} className="card" alt="logo" /> */}
        DATA
      </div>
      <div className="card">
        {/* <img src={logo} className="card" alt="logo" /> */}
        Triboro
      </div>
    </div>
  );
}
export default ContactPage;
